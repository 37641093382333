<template>
  <common-dialog
    :show="show"
    @close="close"
  >
    <template #header>
      {{ currentType.title }}
    </template>
    <template #body>
      <v-row>
        <v-col
          v-if="currentType.weightTypeSelect"
          cols="12"
        >
          <label>Выберите из какого поля будет заполнено значение веса</label>
          <v-radio-group
            v-model="fromWeightField"
            row
          >
            <v-radio
              v-for="weightType in weightTypes"
              :key="weightType.value"
              color="teal"
              class="mb-0"
              :label="weightType.name"
              :value="weightType.value"
            />
          </v-radio-group>
        </v-col>
        <v-col
          cols="2"
        >
          <div class="d-flex align-center pb-3">
            <v-checkbox
              :input-value="allSelected"
              color="grey darken-3"
              :label="`Товары (${selectedWares.length}/${wares.length})`"
              hide-details="true"
              @change="onSelectAll"
            />
          </div>
        </v-col>
        <v-col
          cols="10"
        >
          <v-alert
            v-if="!selectedWares.length"
            class="mb-0"
            dense
            text
            color="orange"
            type="warning"
          >
            Выберите товары
          </v-alert>
        </v-col>
      </v-row>
      <div>
        <div class="d-flex my-4">
          <v-text-field
            ref="rangeField"
            v-model="range"
            hide-details="auto"
            background-color="#EDEDED"
            class="ba-0 rounded-l-sm rounded-r-0"
            filled
            rounded
            placeholder="Введите диапазон"
            @keyup.enter="getIdsFromRange"
          />
          <v-btn
            height="auto"
            class="elevation-0 my-0 rounded-l-0"
            x-large
            dark
            min-width="50px"
            @click="getIdsFromRange"
          >
            <v-icon>mdi-plus</v-icon>
            <span class="d-none d-lg-block">Выбрать</span>
          </v-btn>
        </div>
        <v-virtual-scroll
          v-if="wares.length"
          :items="wares"
          :bench="10"
          :item-height="50"
          :height="height"
        >
          <template #default="{index,item}">
            <copy-field-element
              :headers="headers"
              :item="item"
              :index="index"
              :selected="selectedWares.includes(item.id)"
              @select="onItemSelect"
            />
          </template>
        </v-virtual-scroll>
        <template v-else>
          <p class="text-center">
            Отсутствуют подходящие товары с единицей измерения "{{ measureUnitLetter }}"
          </p>
        </template>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-end mt-5">
        <v-btn
          color="#5CB7B1"
          elevation="0"
          :disabled="isDisabled"
          @click="submit"
        >
          Рассчитать
        </v-btn>
      </div>
    </template>
  </common-dialog>
</template>
<script>
import CommonDialog from "@/components/shared/common-dialog.vue";
import {mapGetters} from "vuex";
import CopyFieldElement from "@/components/documents/copy-field-element.vue";
import {getIdsFromRange} from "@/helpers/inputs";
import {getTypeDetails, WEIGHT_TYPES} from "@/components/dt/goods/fill-with-utils";

export default {
  components: {
    CopyFieldElement,
    CommonDialog
  },
  props: {
    show: {
      type: Boolean
    },
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      type: null,
      fromWeightField: null,
      weightTypes: WEIGHT_TYPES,
      selectedWares: [],
      range: "",
      validRange: "",
      headers: [
        {
          text: "№",
          value: "index",
          flex: 1,
          width: 60
        },
        {
          text: "Код товара",
          value: "tn_ved",
          flex: 1,
          width: 140
        },
        {
          text: "Описание",
          value: "combined_description",
          flex: 18,
          width: 800

        },
        {
          text: "Происх.",
          value: "origin_country_letter",
          flex: 1,
          width: 50
        },
      ],
      wares: []
    }
  },
  computed: {
    ...mapGetters({
      selected: 'dt/getSelected'
    }),
    currentType() {
      return getTypeDetails(this.params.type)
    },
    measureUnitLetter() {
      return this.params?.filter?.measureUnitLetter ?? null
    },
    height() {
      return window.innerHeight - 500
    },
    isDisabled() {
      if (this.currentType.weightTypeSelect) {
        return !this.selectedWares.length || this.fromWeightField === null
      } else {
        return !this.selectedWares.length
      }
    },
    allSelected() {
      return this.wares.length ? this.wares.length === this.selectedWares.length : false
    }
  },
  created() {
    this.fetchWares()
  },
  methods: {
    getIdsFromRange,
    onSelectAll(value) {
      this.selectedWares = value ? this.wares.map(i => i.id) : []
    },
    onItemSelect(id) {
      if (this.selectedWares.includes(id)) {
        this.selectedWares = this.selectedWares.filter(i => i !== id)
      } else {
        this.selectedWares.push(id)
      }
    },
    fetchWares() {
      this.currentType.getFilteredWares({
        measureUnitLetter: this.measureUnitLetter,
        declarationId: this.selected.id,
      }).then(({data}) => this.wares = this.prepareWares(data))
    },
    close() {
      this.$emit('update:show', false)
    },
    combinedDescription(item) {
      return [item?.description, item.specific_description].filter(v => v).join('; ').trim()
    },
    cut(description) {
      return description !== null && description.length > 180 ? description.slice(0, 180) + '...' : description
    },
    updateDocument() {
      return this.$store.dispatch('dt/getDocumentById', this.selected.id)
    },
    submit() {
      this.currentType.submit({
        declarationId: this.selected.id,
        payload: this.selectedWares,
        fromWeightField: this.fromWeightField
      }).catch((err) => {
        this.$error("Не удалось рассчитать значения")
        throw err
      }).then(() => this.updateDocument())
        .then(() => {
          this.$info("Значения обновлены")
          this.close()
        })
    },
    prepareWares(wares) {
      return wares.map((item, idx) => {
        const {
          id,
          tn_ved,
          add_tn_ved,
          origin_country_letter,
        } = item
        const combined_description = this.cut(this.combinedDescription(item))
        return {
          id,
          index: idx + 1,
          tn_ved,
          add_tn_ved,
          combined_description,
          origin_country_letter,
        }
      })
    }

  }
}
</script>
